/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Information"}>
        <SiteHeader />

        <Column className="css-12ekc0m css-5fuono --parallax pb--60 pt--60" name={"ygpgp8q198"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=3000x_.jpg);
    }
  
background-position: 52% 1%;
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"6ic5xm5abvd"} style={{"paddingBottom":4}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--30" content={"TAXI"}>
              </Title>

              <Text className="text-box" content={"Luca<br>&nbsp;+39 331 259 5103"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"7hv3xoq15qd"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--30" content={"PLAN D'ACCES / <br><span style=\"color: rgba(247, 113, 166, 0.71);\">MAPPA DI ACCESSO</span>"}>
              </Title>

              <Text className="text-box" content={"Google Maps:<br><a href=\"https://goo.gl/maps/s1x4C4B5rzY1rsG6A\" target=\"_blank\">https://goo.gl/maps/s1x4C4B5rzY1rsG6A</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/f6a25da585754e78a5bb63b04e801b14_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/f6a25da585754e78a5bb63b04e801b14_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31913/f6a25da585754e78a5bb63b04e801b14_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31913/f6a25da585754e78a5bb63b04e801b14_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31913/f6a25da585754e78a5bb63b04e801b14_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"xqmujkplen"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"ENFANTS"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Comme nous l'avons indiqué, les enfants sont bienvenus à la cérémonie uniquement.&nbsp;<br><br>Nous remercions toutes les familles qui ont trouvé une solution de garde pour ces quelques jours de fête. Nous espérons que vos enfants passeront un très bon moment avec leurs grands-parents (et leurs grands-parents passeront un bon moment également :D)<br><br>Pour les familles venant avec les enfants le 8 juillet nous avons prévu une solution de garde entre 18h et minuit avec un repas enfant prévu mais sans possibilité de dormir sur place.<br><br>Les enfants seront accueillis dans une zone à proximité du cocktail et accessible aux parents.&nbsp;<br><br>Vous avez également une possibilité de laisser les enfants dans cette zone entre 18h et 20h et de les ramener à l'hôtel ensuite. Nous pouvons vous fournir les numéros de baby-sitters.<br><br><br><br>Tous les enfants sont bienvenus au brunch le 9 juillet et restent sous la responsabilité de leurs parents. <br>Veuillez noter la présence de plusieurs piscines dans le domaine. Il est fortement recommandé de mettre les brassards aux enfants qui ne savent pas nager pour éviter tout risque.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgba(247, 113, 166, 0.63);\">BAMBINI</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgba(247, 113, 166, 0.69);\">Come abbiamo indicato,&nbsp;i bambini sono i benvenuti solo alla cerimonia.\n<br><br><br>Ringraziamo tutte le famiglie che hanno trovato una soluzione di cura per questi pochi giorni di festa. Speriamo che i tuoi figli si divertano con i loro nonni (e anche i loro nonni si divertiranno :D)\n<br><br><br>Per le famiglie che vengono con bambini l'8 luglio, abbiamo previsto una soluzione di assistenza all'infanzia tra le 18:00 e mezzanotte con un pasto per bambini fornito ma senza la possibilità di dormire in loco.<br><br>I bambini saranno accolti in un'area vicina al cocktail party e accessibile ai genitori.\n<br><br>&nbsp;Hai anche la possibilità di lasciare i bambini in quest'area tra le 18:00 e le 20:00 e riportarli in albergo successivamente. Possiamo fornirti i numeri della babysitter.<br><br><br><br>Tutti i bambini sono invitati al brunch del 9 luglio e rimangono sotto la responsabilità dei genitori.\nSi prega di notare la presenza di diverse piscine nella proprietà.&nbsp;Si raccomanda vivamente di indossare bracciali per i bambini che non sanno nuotare per evitare qualsiasi rischio.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"p2nx620jiyj"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"REPAS"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<br>Si vous avez des intolérances alimentaires, n'hésitez pas à nous tenir au courant pour la mise en place d'un menu spécial."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"CENA"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Se aveste intolleranze alimentari, non esitare a tenerci informati per la definizione di un menu speciale."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"q2s3tcqc7dc"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--30" style={{"maxWidth":1093}} content={"LOCATION DE VOITURE À CATANE"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"https://www.autoeurope.fr/<br><br>Veuillez noter que toutes les cartes de crédit ne sont pas acceptées par les loueurs.\nLes cartes de débit et les cartes bancaires avec la mention \"Débit\" ne sont pas acceptées par la majorité des loueurs de voitures!<br><br>A votre arrivée à Catania, 2 options s’offrent à vous en fonction de votre compagnie de location:<br>1. La compagnie de location se trouve dans le terminal d'arrivée =&gt; vous pouvez vous y adresser directement\n<br>2. La compagnie de location se trouve à l'extérieur =&gt; dirigez vous à droite et allez vers le Terminal C en suivant les indications \"Car rent\""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgba(247, 113, 166, 0.77);\">NOLEGGIO AUTO A CATANIA</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"https://www.autoeurope.fr/<br><span style=\"color: rgba(247, 113, 166, 0.76);\"><br>Si prega di notare che non tutte le carte di credito sono accettate dalle società di noleggio.\nLe carte di debito e le carte bancarie contrassegnate con \"Debito\" non sono accettate dalla maggior parte delle compagnie di autonoleggio!<br><br>Quando arrivi a Catania, hai 2 opzioni a seconda della compagnia di noleggio:\n<br>1. La compagnia di noleggio si trova nel terminal di arrivo =&gt; puoi contattarla direttamente\n<br>2. La compagnia di noleggio è fuori =&gt; gira a destra e vai al Terminal C seguendo le indicazioni \"Autonoleggio\"</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60 pt--60" name={"783i67y0n9l"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/c8fe3d8b022b4641ac3b078dd28ab2b8_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/bf5790410063462997a2a9eced906198_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/bf5790410063462997a2a9eced906198_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31913/bf5790410063462997a2a9eced906198_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/ecb4c69dcb27428f8217f586e019c771_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/ecb4c69dcb27428f8217f586e019c771_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31913/ecb4c69dcb27428f8217f586e019c771_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/e187923f5690464c8a32286de08b354e_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/e187923f5690464c8a32286de08b354e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31913/e187923f5690464c8a32286de08b354e_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/26e03c5cc5c543e5a3772dbf0fcb4a00_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/26e03c5cc5c543e5a3772dbf0fcb4a00_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31913/26e03c5cc5c543e5a3772dbf0fcb4a00_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/c00f2c7238be40d8ba3b48201aa3f70b_s=350x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/c00f2c7238be40d8ba3b48201aa3f70b_s=350x_.jpeg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/71a164f86b9a47288544d4c3bf45c137_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/71a164f86b9a47288544d4c3bf45c137_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31913/71a164f86b9a47288544d4c3bf45c137_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31913/568456b8ac71441f95e362a448f45339_s=350x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31913/568456b8ac71441f95e362a448f45339_s=350x_.jpeg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"8i13ywukrxp"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"PHOTOS"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Nous vous demandons, dans la mesure du possible, de ne pas publier les photos de la cérémonie et des enfants sur les réseaux sociaux.&nbsp;<br>Merci pour votre compréhension ♡<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgba(247, 113, 166, 0.63);\">IMMAGINI</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgba(247, 113, 166, 0.73);\">Vi chiediamo, per quanto possibile, di non pubblicare sui social le foto della cerimonia e dei bambini.\nGrazie per la tua comprensione ♡</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"irwxaaq6nz"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--43 swpf--uppercase" content={"Hotels"}>
              </Title>

              <Text className="text-box swpf--uppercase" content={"Masseria della Volpe<br> Il San Corrado di Noto   <br>\n Battimandorlo\n<br>Country House Villadorata<br>Hotel Q92<br>Gagliardi Boutique Hotel<br>Masseria degli Ulivi<br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"h88dgzmjij9"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--43" content={"PLAGES"}>
              </Title>

              <Text className="text-box" content={"SPIAGGIA ELORO<br>SPIAGGIA VENDICARI<br>SPIAGGIA CALAMOSCHE<br>SPIAGGIA MARIANELLI<br>PIZZUTA BEACH<br>SPIAGGIA DI SAN LORENZO<br>LIDO DI NOTO<br>LIDO AGUA BEACH<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" name={"gift-registry-1"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 mt--50 flex--top" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-ehoebf --center --parallax pb--80 pt--60" name={"footer"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box" content={"<span style=\"color: rgb(249, 249, 249);\">Nous avons hâte de vous voir!</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style=\"color: rgb(246, 247, 249);\">Non vediamo l'ora di vedervi!</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}